.detail {
  width: 100%;
}
.detail .box-content-table-header {
  border-bottom: none !important;
}

.detail .MuiTableCell-head {
  background-color: transparent !important;
}
